import jQuery from 'jquery';
import 'slick-carousel';
import Rellax from 'rellax';
import ScrollReveal from 'scrollreveal';

($ => {
  'use strict';

  $(document).ready(() => {
    //--------------------------------------------------------------
    // Paralax
    //--------------------------------------------------------------
    // https://github.com/dixonandmoe/rellax
    var rellax = new Rellax('.Banner-image', {
      speed: -4
    });

    //--------------------------------------------------------------
    // Scroll Reveal
    //--------------------------------------------------------------
    // https://scrollrevealjs.org/
    ScrollReveal().reveal('.reveal--sequence', {
      duration: 800,
      interval: 100
    });

    ScrollReveal().reveal('.reveal--fromLeft', {
      origin: 'left',
      delay: 100,
      distance: '30px',
      duration: 800
    });

    ScrollReveal().reveal('.reveal--fromRight', {
      origin: 'right',
      delay: 100,
      distance: '30px',
      duration: 800
    });

    ScrollReveal().reveal('.reveal--fromBottom', {
      origin: 'bottom',
      delay: 100,
      distance: '30px',
      duration: 800
    });

    //--------------------------------------------------------------
    // Carousels
    //--------------------------------------------------------------
    // https://kenwheeler.github.io/slick/
    $('.Carousel--featuredCategories .Carousel-carousel').slick({
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 4,
      prevArrow:
        '<i class="Carousel-arrow Carousel-arrow--left fal fa-chevron-left"></i>',
      nextArrow:
        '<i class="Carousel-arrow Carousel-arrow--right fal fa-chevron-right"></i>',
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
            infinite: true
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3
          }
        },
        {
          breakpoint: 620,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        }
      ]
    });

    //--------------------------------------------------------------
    // Header Toggle Nav
    //--------------------------------------------------------------
    $('.NavigationToggle').click(function() {
      $(this).toggleClass('is-open');
      $('.Navigation--mobile').slideToggle(200);
    });

    $('.Navigation--mobile li')
      .has('ul')
      .prepend(
        '<span class="Navigation-arrow"><i class="fal fa-chevron-down"></i></span>'
      );
    $('.Navigation-arrow').click(function() {
      $(this)
        .find('i')
        .toggleClass('fa-chevron-down');
      $(this)
        .find('i')
        .toggleClass('fa-chevron-up');

      $(this)
        .parent()
        .find('ul:first')
        .slideToggle(200);
    });
  });
})(jQuery);
